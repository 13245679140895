@import 'src/styles/core.sass';

.editor-mode {
  margin-left: 24px;
  display: flex;
  font-size: 10px;
  &__ver {
    margin-left: 8px;
    font-weight: bold; }
  &__mode {
    display: flex;
    align-items: center;
    button {
      padding: 1px 3px;
      margin: 0;
      background: transparent;
      border: 1px solid #fff;
      border-radius: 3px;
      user-select: none;
      &.current,
      &:focus,
      &:disabled {
        cursor: default;
        opacity: 1;
        background: transparent; }
      &.current {
        background: #fff;
        color: $black-1; }
      &:hover:not(:disabled) {
        background: transparent;
        opacity: 0.6; }
      & + button {
        border-left: none; }
      &.simple-mode {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      &.advanced-mode {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; } } } }
