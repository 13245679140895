@import 'src/styles/core.sass';

@keyframes loadingBox {
  0% {
    height: 0; }

  100% {
    height: 100%; } }

.export-modal {
  &__details {
    display: none;
    margin: 24px 0 0;
    h2 {
      font-size: 16px;
      font-weight: normal;
      margin: 0 0 24px;
      padding: 0; }
    &.active {
      display: block; }
    &__actions {
      display: flex;
      align-items: center;
      margin-top: 18px;
      p {
        font-size: 12px;
        margin: 0;
        padding: 0 12px 0 0; }
      button {
        display: flex;
        align-items: center;
        padding: 8px 12px;
        margin: 0;
        background: $blue-1;
        font-size: 12px;
        border-radius: $base-border-radius;
        &:hover,
        &:focus {
          background: $blue-1;
          opacity: 0.8; }
        .icon {
          margin-right: 8px; }
        &:first-of-type {
          margin-right: 8px; } } }
    &__format-selection {
      button {
          border-radius: 0;
          padding: 8px 14px;
          margin: 0 0 12px;
          font-size: 12px;
          background: $grey-5;
          transition: 0.5s all;
          &:first-child {
            border-top-left-radius: $base-border-radius;
            border-bottom-left-radius: $base-border-radius; }
          &:last-child {
            border-top-right-radius: $base-border-radius;
            border-bottom-right-radius: $base-border-radius; }
          &:hover,
          &:focus {
            background: $blue-1;
            opacity: 0.5; }
          &.active {
            background: $blue-1;
            opacity: 1; } } }
    &__input-range {
      label {
        font-size: 12px;
        margin: 0 0 12px;
        padding: 0;
        display: inline-block; }
      input[type="range"] {
        appearance: none;
        background-color: transparent;
        width: 100%;
        margin-bottom: 24px;
        &::-webkit-slider-runnable-track {
          position: relative;
          height: 6px;
          background-color: $grey-4;
          border-radius: 8px; }
        &::-moz-range-track {
          position: relative;
          height: 6px;
          background-color: $grey-4;
          border-radius: 8px; }
        &::-webkit-slider-thumb {
          box-sizing: border-box;
          appearance: none;
          position: relative;
          background-color: $blue-1;
          cursor: pointer;
          border: 2px solid #fff;
          border-radius: 50px;
          width: 18px;
          height: 18px;
          top: 50%;
          translate: 0 -50%; }
        &::-moz-range-thumb {
          box-sizing: border-box;
          appearance: none;
          position: relative;
          background-color: $blue-1;
          cursor: pointer;
          border: 2px solid #fff;
          border-radius: 50px;
          width: 18px;
          height: 18px; }
        &::-moz-range-progress {
          height: 8px;
          background-color: $blue-3;
          border-radius: 8px; } } } }
  &__item {
    width: 100px;
    height: 100px;
    position: relative;
    margin-right: 20px;
    border: 1px solid $grey-4;
    border-radius: $base-border-radius;
    font-size: 11px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    transition: all 0.5s;
    background: none;
    color: $grey-7;
    &__title {
      text-transform: uppercase; }
    &:focus,
    &:hover,
    &.active {
      background: $blue-2;
      border-color: transparent; }
    &.loading {
      border-color: $grey-4 !important;
      &:before {
        content: '';
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 0;
        background: $grey-4;
        opacity: 0.3;
        animation: loadingBox 2s linear infinite; } }
    &[disabled] {
      background: none;
      opacity: 1;
      img {
        opacity: 0.3; } }
    &:last-child {
      margin-right: 0;
      cursor: pointer; }
    img {
      width: 70px; } }
  &__file {
    display: flex; } }
