@import 'src/styles/core.sass';

.editor-header {
  position: relative;
  z-index: 5;
  height: $header-height;
  display: flex;
  justify-content: space-between;
  color: #fff;
  background-color: $header-bg-color;
  &.template-shared-owner,
  &.template-shared {
    background: $green;
    color: #fff;
    .editor-header__app-actions {
      background: $green; }
    .editor-header__user-actions {
      width: 355px;
      .dnd-button {
        border: 1px solid #fff;
        color: #fff;
        background: transparent;
        .icon {
          margin-right: 8px; } } } }
  &__column {
    display: flex;
    justify-content: space-between;
    align-items: center;
    &:not(:last-child) {
      margin-right: $grid-gap;
      padding-right: 16px; } }

  // left column
  &__app-actions {
    font-weight: 700;
    width: $editor-sidebar-width;
    background: $top-bar-gradient; }

  // middle column
  &__template-actions {
    flex-grow: 1; }
  &__panel-template-options {
    background-color: transparent !important;
    .gjs-pn-buttons {
      .gjs-pn-btn {
        height: 24px;
        width: 24px;
        min-height: initial;
        min-width: initial;
        color: #fff;
        box-shadow: none;
        &[data-tooltip*="export"] {
          background: $green; } } } }
  &__help-btn {
    margin-left: 5px;
    font-size: 14px;
    color: #fff; }

  &__template-info {
    flex-grow: 1;
    display: flex;
    justify-content: center;
    &__shared-template-title {
      text-align: center;
      font-size: 14px;
      width: 400px;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis; }
    .dnd-button {
      font-weight: 500;
      &__label {
        display: flex;
        align-items: center;
        justify-content: center; }
      .icon {
        margin: 3px 0 0 5px;
        font-size: 12px; } } }

  &__template-name {
    max-width: 250px;
    &__name {
      display: block;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      line-height: 18px; } }

  &__simple-btn {
    color: inherit;
    font-size: 14px;
    padding: 8px;
    .icon-expand-more {
      margin-left: 4px; }
    .icon-visibility,
    .icon-mail {
      margin-right: 4px; } }

  // right column
  &__user-actions {
    min-width: $editor-traits-sidebar-width;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding: 0 16px;
    .dnd-button-base {
      flex-shrink: 0;
      font-size: 12px;
      padding: 6px 14px;
      box-shadow: none;
      &:not(:last-child) {
        margin-right: 5px; }
      .icon {
        font-size: 14px; } } }
  &__preview-btn {
    &.dnd-button-base {
      background: $purple-1; } }
  &__preview-btn,
  &__update-btn {
    .dnd-button__label {
      flex-direction: row-reverse;
      span {
        margin-right: 8px; } } }
  &__cancel-btn {
    color: #fff; } }

@media (max-width: 1400px) {
  #editor {
    .editor-content {
      height: calc(100vh - 80px); }
    .preview-header {
      flex: wrap;
      align-items: start;
      height: 80px;
      .dnd-breadcrumb {
        width: 100%;
        height: 40px; }
      &__right-col {
        height: 40px;
        display: flex;
        align-items: center; }
      &__devices {
        top: auto;
        bottom: 0;
        transform: translate(-50%, 0); } }
    .grapes .gjs-cv-canvas.preview .gjs-cv-canvas__frames.pc .gjs-frame {
      padding-top: 80px; } } }

@media (max-width: 1600px) {
  .editor-header {
   height: 80px;
   flex-wrap: wrap;
   &__column {
    margin-right: 0 !important; }
   &__app_actions {
    width: 100%; }
   &__user-actions {
    width: 100%;
    padding: 8px 16px;
    justify-content: center; } } }

@media (max-width: 1200px) {
  #editor {
    .preview-header {
      .dnd-breadcrumb {
        display: none; } } }
  .templates-header {
    flex-direction: column;
    align-items: flex-start;
    height: 80px;
    &__logo,
    &__content {
      height: 40px;
      width: 100%; }
    &__content {
      padding-left: 8px; } }
  .editor-header {
    height: 120px;
    &__template-actions {
      width: 100%; }
    &__panel-template-options {
      .gjs-pn-buttons {
        padding-left: 14px; } } } }
