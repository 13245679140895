@import 'src/styles/core.sass';

.grapes {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .style-social-icons-component {
    height: 150px;
    overflow-y: scroll;
    button[data-style-social-icon-button] {
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: space-between;
      background: none !important;
      span {
        font-size: 11px;
        display: block;
        color: $black; }
      img {
        width: 100px; }
      &:last-child {
        margin-right: 0; }
      &:hover,
      &.selected {
        background: $purple-1 !important;
        span {
          color: #fff; } } } }
  .add-social-icons-component {
    .tabset {
      input[type="radio"] {
        position: absolute;
        left: -200vw; }
      .tab-panel {
        display: none;
        padding: 8px;
        margin: 0; }
      input:first-child:checked ~ .tab-panels > .tab-panel:first-child,
      input:nth-child(3):checked ~ .tab-panels > .tab-panel:nth-child(2),
      input:nth-child(5):checked ~ .tab-panels > .tab-panel:nth-child(3) {
        display: flex;
        flex-wrap: wrap; }
      label {
        cursor: pointer;
        display: inline-block;
        padding: 4px 6px;
        font-size: 10px;
        border-radius: 2px; }
      input {
        &:hover,
        &:focus + label,
        &:checked + label {
          background-color: $purple-2;
          color: #fff; } } }
    button[data-add-social-icon-button] {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none !important;
      color: $editor-right-panel-label-color !important;
      width: 28px;
      margin: 0 5px 5px 0;
      padding: 0 !important;
      &:last-child {
        margin-right: 0; }
      &.selected {
        border: 1px solid $purple-2; } } }
  .component-settings .hide-element-component {
    display: flex;
    button[data-hide-elem-button] {
      display: flex;
      font-size: 10px;
      line-height: 1;
      box-shadow: $block-shadow;
      background: #fff !important;
      color: $black !important;
      width: 32px;
      margin-right: 8px;
      overflow: hidden;
      display: flex;
      align-items: center;
      justify-content: center;
      &.selected {
        color: #fff !important;
        background: $purple-2 !important; } } }
  .video-play-buttons-component {
    display: flex;
    button[data-video-play-button] {
      display: flex;
      align-items: center;
      justify-content: center;
      background: none !important;
      color: $editor-right-panel-label-color !important;
      width: 37px;
      margin: 0 5px 0 0;
      padding: 5px !important;
      &:last-child {
        margin-right: 0; }
      &.selected {
        border: 1px solid $purple-2; } } }
  .gjs-sm-clear {
    cursor: pointer;
    width: 14px;
    min-width: 14px;
    height: 14px;
    margin-left: 3px; }
  .gjs-editor-cont .sp-container {
    .sp-picker-container {
      width: 220px; }
    .sp-input-container {
      width: 150px; }
    .sp-input {
      position: relative;
      z-index: 1;
      padding: 6px;
      border: 1px solid #fff;
      border-radius: 0;
      text-align: center;
      &:focus {
        border-color: $blue-1; } }
    .sp-button-container {
      float: right; }
    .sp-cancel {
      bottom: -3px; }
    .sp-choose {
      background: #fff;
      border-color: $blue-1;
      color: $blue-1; } }
  // common panel styles
  .gjs-sm-sector__quickSector {
    .gjs-sm-properties {
      .select-image-btn,
      .select-product-btn {
        margin-top: 8px; } } }

  .gjs-pn-panel {
    position: relative;
    padding: 0; }

  .gjs-pn-btn {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 40px;
    height: 32px;
    box-shadow: $block-shadow;
    border-radius: $base-border-radius;
    font-size: 14px;
    &.gjs-disabled {
      pointer-events: none; }
    &:last-child {
      margin-right: 0; } }

  // common title styles
  .editor-content__blocks-list, .gjs-pn-views-container {
    .gjs-title, .gjs-sm-sector-title {
      align-items: center;
      border-bottom: 1px solid $grey-4;
      display: flex;
      font-size: 11px;
      font-weight: 700;
      height: 40px;
      padding: 8px 16px;
      position: relative;
      text-transform: uppercase;
      &:after {
        @include pseudo-expand-more;
        position: absolute;
        right: 16px; } }

    .simple-mode .gjs-title {
      display: none !important; }
    .gjs-open, .gjs-sm-open {
      .gjs-title, .gjs-sm-sector-title {
        &:after {
          @include pseudo-expand-less;
          line-height: 1;
          position: absolute; } } } }

  // blocks sidebar styles
  .gjs-pn-blocks-tabs,
  .gjs-pn-traits-tabs {
    background-color: transparent !important;
    .gjs-pn-btn {
      flex: 1 0;
      flex-direction: row;
      border-radius:  0;
      height: auto;
      line-height: 1;
      padding: 16px;
      background: #eee;
      box-shadow: none;
      margin-right: 0;
      font-weight: 700;
      color: $black;
      font-size: 12px;
      border-left: 1px solid $grey-5;
      justify-content: flex-start;
      .icon {
        font-size: 16px;
        color: $blue-1;
        margin-right: 8px; }
      &.gjs-pn-active {
        background-color: #fff;
        color: $black; } } }

  .editor-content__blocks-list {
    .custom-module-buttons-wrapper {
      position: absolute;
      top: 8px;
      right: 50px;
      display: flex;
      align-content: center; }

    .delete-custom-module-btn,
    .edit-custom-module-btn {
      width: 24px;
      height: 24px;
      padding: 0;
      background-color: transparent;
      display: flex;
      align-items: center;
      justify-content: center;
      color: $secondary-btn-color;
      .icon {
        font-size: 14px; } }
    .gjs-blocks-cs {
      height: 100%;
      .gjs-block-categories,
      .gjs-blocks-no-cat {
        max-height: 100%;
        overflow-y: scroll; }
      .gjs-title {
        background-color: $grey-3;
        color: $black;
        &:after {
          right: 10px; }
        i {
          display: none; } }
      .gjs-block-category {
        position: relative;
        &--custom {
          .gjs-title {
            background-color: $blue-2; } } }
      .gjs-block {
        position: relative;
        color: $black;
        border: 1px solid $grey-4;
        box-shadow: none;
        min-width: 30px;
        width: calc(33.3333333333% - 16px);
        margin: 8px;
        align-items: center;
        justify-content: flex-start;
        border-radius: $base-border-radius;
        transition: 0.5s all ease;
        &:hover:not(.gjs-block--lock) {
          border-color: transparent;
          box-shadow: $blue-1 0px 1px 2px, $blue-1 0px 0px 0px 2px; }
        &:before, svg {
          width: 40px;
          height: 40px; }
        &[title="2 Columns (3/7)"]:before {
          content: '';
          @include bg-image('/images/panel-icons/37.svg', 100%, center); }
        &[title="4 Columns"]:before {
          content: '';
          @include bg-image('/images/panel-icons/4col.svg', 100%, center); }
        &[title="3 Columns"]:before {
          content: '';
          @include bg-image('/images/panel-icons/3col.svg', 100%, center); }
        &[title="2 Columns (8/4)"]:before {
          content: '';
          @include bg-image('/images/panel-icons/84.svg', 100%, center); }
        &[title="2 Columns (4/8)"]:before {
          content: '';
          @include bg-image('/images/panel-icons/48.svg', 100%, center); }
        &[title="2 Columns (9/3)"]:before {
          content: '';
          @include bg-image('/images/panel-icons/93.svg', 100%, center); }
        &[title="2 Columns (3/9)"]:before {
          content: '';
          @include bg-image('/images/panel-icons/39.svg', 100%, center); } }
      .gjs-thumb-label {
        img {
          pointer-events: none; }
        &--big {
          width: 100%;
          border: none;
          padding: 0;
          margin: 16px;
          .gjs-block-label {
            display: none; }
          &:before {
            width: 100%;
            height: 109px; } }
        &--custom {
          position: relative;
          border: 1px solid $grey-2;
          width: 100%;
          .gjs-thumb-label__buttons-wrapper {
            display: flex;
            align-items: center;
            justify-content: flex-end; }
          .gjs-thumb-label__btn {
            width: 24px;
            height: 24px;
            padding: 0;
            background-color: transparent;
            display: flex;
            align-items: center;
            justify-content: center;
            &.default {
              color: $blue-1; }
            &.secondary {
              color: $secondary-btn-color; } } } }
      .bpreview-form-data-table {
        &:before {
          content: '';
          @include bg-image('/images/panel-icons/form-data-table.svg', 100%, center); }
        .gjs-block__media {
          position: absolute;
          top: -8px;
          left: 50%;
          transform: translateX(-50%);
          color: #fff;
          background-color: $blue-1;
          font-size: 10px;
          border-radius: 10px;
          width: 70%;
          padding: 1px 0; } }
      .bpreview-qr-code:before {
        content: '';
        @include bg-image('/images/panel-icons/qr.svg', 100%, center); }
      .bpreview-name:before {
        content: '';
        @include bg-image('/images/panel-icons/name.svg', 100%, center); }
      .bpreview-icon:before {
        content: '';
        @include bg-image('/images/panel-icons/icon.svg', 100%, center); }
      .bpreview-phone:before {
        content: '';
        @include bg-image('/images/panel-icons/phone.svg', 100%, center); }
      .bpreview-email:before {
        content: '';
        @include bg-image('/images/panel-icons/mail.svg', 100%, center); }
      .bpreview-divide-section:before {
        content: '';
        @include bg-image('/images/panel-icons/2col.svg', 100%, center); }
      .bpreview-big-heading:before {
        content: '';
        @include bg-image('/images/panel-icons/big-heading.svg', 100%, center); }
      .bpreview-image-text:before {
        content: '';
        @include bg-image('/images/panel-icons/image-text.svg', 100%, center); }
      .bpreview-discount-code:before {
        content: '';
        @include bg-image('/images/panel-icons/discount_code.svg', 100%, center); }
      .bpreview-add-space:before {
        content: '';
        @include bg-image('/images/panel-icons/spacer.svg', 100%, center); }
      .bpreview-page-wrapper:before {
        content: '';
        @include bg-image('/images/panel-icons/page-wrapper.svg', 100%, center); }
      .bpreview-button:before {
        content: '';
        @include bg-image('/images/panel-icons/button.svg', 100%, center); }
      .bpreview-create-new-section:before {
        content: '';
        @include bg-image('/images/panel-icons/1col.svg', 100%, center); }
      .bpreview-quote:before {
        content: '';
        @include bg-image('/images/panel-icons/quote.svg', 100%, center); }
      .bpreview-paragraph:before {
        content: '';
        @include bg-image('/images/panel-icons/paragraph.svg', 100%, center); }
      .bpreview-link:before {
        content: '';
        @include bg-image('/images/panel-icons/link.svg', 100%, center); }
      .bpreview-divider:before {
        content: '';
        @include bg-image('/images/panel-icons/divider.svg', 100%, center); }
      .bpreview-single-image:before {
        content: '';
        @include bg-image('/images/panel-icons/image.svg', 100%, center); }
      .bpreview-linked-image:before {
        content: '';
        @include bg-image('/images/panel-icons/linked-image.svg', 100%, center); }
      .bpreview-background-image:before {
        content: '';
        @include bg-image('/images/panel-icons/bg-image.svg', 100%, center); }
      .bpreview-grid-items:before {
        content: '';
        @include bg-image('/images/panel-icons/grid.svg', 100%, center); }
      .bpreview-video:before {
        content: '';
        @include bg-image('/images/panel-icons/video.svg', 100%, center); }
      .bpreview-menu:before {
        content: '';
        @include bg-image('/images/panel-icons/menu.svg', 100%, center); }
      .bpreview-custom-code {
        @include block-lock;
        &:before {
          content: '';
          @include bg-image('/images/panel-icons/code.svg', 100%, center); } }
      .bpreview-products-integration {
        @include block-lock;
        &:before {
          content: '';
          @include bg-image('/images/waymore/waymore_product.svg', 100%, center); } }
      .bpreview-social-follow-block:before {
        content: '';
        @include bg-image('/images/panel-icons/socialfollow.svg', 100%, center); }
      .bpreview-social:before {
        content: '';
        @include bg-image('/images/panel-icons/social.svg', 100%, center); }
      .bpreview-timer:before {
        content: '';
        @include bg-image('/images/panel-icons/timer.svg', 100%, center); }
      .gjs-block-label {
        text-align: center;
        font-size: 10px;
        font-weight: 700;
        margin-top: 12px; } } }

  // canvas actions styles
  .editor-content__canvas-actions {
    .gjs-pn-panel {
      background: transparent !important; }
    .gjs-pn-btn {
      background-color: #fff;
      color: $blue-1;
      &.gjs-pn-active {
        color: #fff;
        background-color: $blue-1; } } }

  .gjs-pn-canvas-history {
    box-shadow: $block-shadow;
    border-radius: 31px;
    overflow: hidden;
    .gjs-pn-btn {
      margin: 0;
      padding: 0;
      width: 50px;
      height: 100%;
      box-shadow: none;
      &.icon-undo {
        border-top-right-radius: 0;
        border-bottom-right-radius: 0; }
      &.icon-history {
        border-radius: 0; }
      &.icon-redo {
        border-top-left-radius: 0;
        border-bottom-left-radius: 0; }
      &:not(:last-child) {
        border-right: 1px solid $bg-color; } } }

  // canvas styles
  // Editor overrides
  .gjs-cv-canvas {
    position: static;
    height: 100%;
    width: 100%;
    box-shadow: 0 0 5px rgba(0,0,0,0.2);
    box-sizing: border-box;
    overflow: hidden;
    z-index: 1;
    .gjs-toolbar {
      div {
        display: flex;
        align-items: center; } }
    .gjs-toolbar-item {
      .lock-status {
        opacity: 0.4;
        cursor: not-allowed; }
      &.active {
        background-color: rgba(0, 0, 0, 0.2); } }
    &.preview {
      position: fixed;
      display: flex;
      justify-content: center;
      align-items: center;
      z-index: 10;
      background-color: $bg-color;
      .gjs-cv-canvas__frames {
        position: static;
        background-repeat: no-repeat;
        background-position: center;
        background-size: contain;
        &.pc {
          .gjs-frame {
            padding-top: $header-height; } }
        &.tablet {
          width: 556px;
          height: 768px;
          padding: 53px 30px;
          background-image: url('/images/backgrounds/tablet.png');
          box-shadow: 0px 20px 20px rgba(black, 0.2);
          border-radius: 30px;
          @media (max-height: 771px) {
            transform: scale(0.78); }
          @media (max-width: 500px),(max-height: 709px) {
            transform: scale(0.58); }
          @media (max-width: 350px), (max-height: 567px) {
            transform: scale(0.3); }
          @media (max-width: 300px), (max-height: 420px) {
            transform: scale(0.25); }
          @media (max-width: 180px), (max-height: 350px) {
            transform: scale(0.2); }
          .gjs-frames {
            @include size(100%);
            border-radius: $base-border-radius;
            overflow: hidden; }
          .gjs-frame-wrapper {
            @include size(100%);
            width: 100% !important;
            position: static; }
          .gjs-frame {
            @include size(100%);
            position: static; } }
        &.mobile {
          width: 370px;
          height: 736px;
          border-radius: 60px;
          padding: 24px 26px;
          box-shadow: 0px 20px 20px rgba(#000, 0.2);
          background-image: url('/images/backgrounds/mobile.png');
          @media (max-height: 940px), (max-width: 414px) {
            transform: scale(0.96); }
          @media (max-height: 920px), (max-width: 400px) {
            transform: scale(0.88); }
          @media (max-height: 855px), (max-width: 350px) {
            transform: scale(0.8); }
          @media (max-height: 810px), (max-width: 300px) {
            transform: scale(0.6); }
          @media (max-height: 700px), (max-width: 250px) {
            transform: scale(0.4); }
          @media (max-height: 490px) {
            transform: scale(0.3); }
          @media (max-height: 390px) {
            transform: scale(0.2); }
          .gjs-frames {
            @include size(100%);
            border-radius: 30px;
            overflow: hidden; }
          .gjs-frame-wrapper {
            @include size(100%);
            width: 100% !important;
            position: static; }
          .gjs-frame {
            @include size(100%);
            position: static; } } } } }

  // traits sidebar styles
  .gjs-pn-traits-tabs {
    .gjs-pn-btn {
      border-right: 1px solid $grey-5;
      border-left: none; } }

  .gjs-pn-views-container {
    display: block;
    width: 100%;
    padding: 8px 0 32px;
    box-shadow: none;
    .gjs-trt-traits {
      padding: 16px;
      .gjs-trt-trait__wrp button {
        background: $purple-2;
        color: #fff;
        padding: 8px;
        border-radius: $base-border-radius; } }
    .gjs-traits-label, .gjs-trt-traits, .gjs-trt-header {
      font-weight: bold;
      margin-bottom: 0;
      border: 0; }
    .gjs-trt-trait {
      flex-direction: column;
      align-items: stretch;
      padding: 5px 0; }
    .gjs-label-wrp {
      width: 100%;
      margin-bottom: 4px;
      font-size: 10px;
      font-weight: 500; }
    .gjs-field-wrp {
      width: 100%; }
    .gjs-field:not(.gjs-field-checkbox) {
      font-size: 10px;
      width: 100%; }
    .gjs-field-select {
      select {
        padding: 0.7em 1.4em; } }
    .gjs-field-checkbox input {
      display: none; }
    .gjs-field-wrp--select {
      font-size: 10px;
      position: relative;
      > div::before {
        @include pseudo-expand-more;
        line-height: 1;
        position: absolute;
        right: 10px;
        top: 10px;
        z-index: 2;
        pointer-events: none; } }
    .gjs-field-full-width {
      width: 100%;
      margin: 0;
      padding: 0;
      div {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        input {
          width: 70%; } } }
    .gjs-clm-header {
      display: flex;
      justify-content: space-between;
      align-content: center;
      flex-wrap: wrap;
      .gjs-field.gjs-select {
        margin-bottom: 0; }
      select#gjs-clm-states {
        padding-left: 15px;
        padding-right: 15px;
        line-height: 15px; } }
    .gjs-clm-tags {
      padding: 0;
      border-bottom: 1px solid $grey-4;
      #gjs-clm-label {
        font-weight: bold; } }
    .gjs-sm-header {
      font-weight: bold; }
    .gjs-sm-sectors {
      .gjs-sm-sector {
        font-size: $font-size-m * 0.8;
        &.gjs-sm-open {
          .gjs-sm-sector-title {
            .gjs-sm-sector-caret:after {
              content: '\e924';
              font: $font-icons; } } }
        & .gjs-sm-sector-title {
          background: $grey-3;
          color: $black;
          border-bottom: 1px solid $grey-4;
          padding: 11px 16px;
          position: relative; }
        .gjs-sm-sector-title {
          i,
          svg {
            display: none; }
          .gjs-sm-sector-caret {
            position: absolute;
            right: 16px;
            width: auto;
            height: auto;
            min-width: auto;
            transform: none;
            &:after {
              content: '\e904';
              font: $font-icons; } }
          &:before {
            font-family: 'icons';
            font-size: 16px;
            color: $purple-1; }
          &:after {
            display: none; } }
        & .gjs-sm-sector-label {
            font-size: 11px;
            font-weight: 700;
            text-transform: uppercase;
            margin-left: 10px; }
        &__general {
          & .gjs-sm-sector-title {
            @extend .icon-settings; } }
        // Quick settings manually ordering
        &__quickSector {
          .gjs-sm-property {
            &__text-shadow {
              order: 1; }
            &__line-height {
              order: 0; } }
          & .gjs-sm-sector-title {
            background-color: $purple-1;
            color: #fff;
            &:before {
              color: #fff; }
            @extend .icon-handyman; } }
        &__typography {
          & .gjs-sm-sector-title {
            @extend .icon-text-format; } }
        &__dimension {
          & .gjs-sm-sector-title {
            @extend .icon-straighten; } }
        &__decorations {
          & .gjs-sm-sector-title {
            @extend .icon-palette; } }
        &__extra {
          & .gjs-sm-sector-title {
            @extend .icon-business-center; } } }
      .gjs-sm-sector-title {
        font-weight: bold;
        i {
          display: inline-block;
          margin-right: 20px;
          padding-right: 0; } }

      .gjs-sm-properties {
        padding: 0 16px 8px;
        overflow: hidden;
        .gjs-sm-field, .gjs-sm-layers {
          margin: 0;
          border-radius: $base-border-radius; }
        select:not(.gjs-input-unit) {
          cursor: pointer;
          padding: 0.7em 1.4em;
          position: relative; }
        .gjs-sel-arrow {
          z-index: 1;
          pointer-events: none; }
        input[type="text"] {
          margin-bottom: 0; }
        input[type="range"] {
          -webkit-appearance: none;
          width: 100%;
          background: $transparent;
          &:focus {
            outline: none; }
          &::-webkit-slider-thumb {
            -webkit-appearance: none;
            @include size(16px);
            transform: translateY(-20%);
            background-color: $blue-1;
            border-radius: 100%; }
          &::-moz-range-thumb {
            cursor: pointer;
            @include size(16px);
            background-color: $blue-1;
            border-radius: 100%; }
          &::-ms-thumb {
            cursor: pointer;
            @include size(16px);
            background-color: $blue-1;
            border-radius: 100%; }
          &::-webkit-slider-runnable-track {
            width: 100%;
            height: 1px;
            cursor: pointer;
            background: $grey-2; }
          &::-moz-range-track {
            width: 100%;
            height: 1px;
            cursor: pointer;
            background: $grey-2; }
          &::-ms-track {
            width: 100%;
            cursor: pointer;
            background: $transparent;
            border-color: $transparent;
            color: $transparent;
            box-shadow: 0px 0px 0px 1px $grey-2;
            height: 1px; } }
        .gjs-radio-item {
          border: 0;
          flex: 0 0 auto;
          border-radius: $base-border-radius;
          height: 27px;
          margin-right: 7px;
          min-width: 41px;
          input {
            display: none; }
          label {
            margin: 0;
            height: 100%;
            font-weight: 700;
            font-size: 10px;
            line-height: 1;
            box-shadow: $block-shadow;
            border-radius: $base-border-radius;
            overflow: hidden;
            display: flex;
            align-items: center;
            justify-content: center;
            &:before {
              font-size: 12px; } } }
        .gjs-sm-label {
          font-size: 10px;
          font-weight: 500;
          margin: 10px 0 5px; }
        #gjs-sm-add {
          top: -22px;
          color: #000; }
        .gjs-sm-btn {
            margin: 0;
            border: none; }
        #gjs-sm-close-layer {
          top: -8px;
          right: 6px; }
        .gjs-input-unit {
          border-radius: 0;
          border: 0;
          cursor: pointer;
          box-shadow: none;
          height: 100%;
          margin-right: 2px;
          color: #000; } } }
    .gjs-sm-property {
      margin-bottom: 0;
      padding: 0 3px;
      #gjs-sm-border-bottom-right-radius {
        order: 3; }
      #gjs-sm-border-bottom-left-radius {
        order: 2; }
      .gjs-sm-properties {
        margin: 10px 0 0; } }
    .gjs-layer {
      .gjs-layer-title {
        font-family: $base-font-family-editor;
        letter-spacing: 0;
        font-weight: 400; } }
    .gjs-clm-tag {
      span {
        display: inline-block;
        line-height: 1; }
      svg {
        width: 100%;
        height: 100%; } }
    .gjs-clm-tag-status, .gjs-clm-tag-close {
      height: 24px;
      opacity: 1;
      padding: 3px;
      width: 27px; }
    .gjs-clm-tags-btn__add {
      height: 18px;
      padding: 0;
      border-radius: 50%;
      overflow: hidden;
      flex-shrink: 0;
      width: 18px; }

    .gjs-clm-header {
      margin-bottom: 5px;
      @include clearfix; }
    .gjs-clm-header-label {
      padding: 0;
      font-size: 11px; }
    .gjs-clm-sels-info {
      font-size: 11px;
      line-height: 1.17;
      margin: 0;
      padding: 0 16px;
      display: none; }
    .gjs-three-bg {
      font-weight: 400;
      font-size: 11px; }
    .gjs-select {
      .gjs-sel-arrow {
        z-index: 1;
        pointer-events: none; } }
    .gjs-field {
      background-color: transparent;
      margin-bottom: 5px; }
    .gjs-field select, .gjs-field:not(.gjs-field-range) input,  textarea {
      border-radius: $base-border-radius;
      border: 1px solid white;
      height: 31px;
      margin: 0;
      .gjs-input-holder, input {
        height: 100%; } }
    .gjs-field-full-width button {
      height: 31px;
      border-radius: $base-border-radius;
      margin-right: 5px; }
    .gjs-field-colorp-c {
      background: none; }
    .gjs-field-colorp {
      padding: 1px; }
    .gjs-field-color-picker {
      border-radius: 0 $base-border-radius $base-border-radius 0; } }

  // Rich text editor styles
  .gjs-rte-toolbar {
    border-color: $grey-3;
    border-radius: $base-border-radius;
    box-shadow: $block-shadow;
    width: 345px;
    &__reverse {
      left: auto !important;
      right: 0 !important; }
    .gjs-rte-actionbar {
      flex-wrap: wrap;
      width: 100%; }
    .gjs-rte-action {
      padding: 4px;
      border: none;
      color: #000;
      width: fit-content;
      order: 3;
      .gjs-rte-select {
        width: 100%; }
      &[title="Font family"] {
        order: 0;
        display: block;
        width: 65%; }
      &[title="Font size"] {
        order: 1;
        display: block;
        width: 35%; }
      &[title="Custom fields"] {
        order: 4;
        display: block;
        width: 119px; }
      .gjs-rte-button {
        position: relative;
        display: block;
        height: 24px;
        width: 24px;
        line-height: 24px;
        margin: 0;
        background-color: #fff;
        &--font-color {
          color: $blue-1; }
        &--background-color {
          background-color: rgba($blue-1, 0.2); }
        &:hover {
          background-color: $grey-3; } }
      .gjs-rte-color {
        position: absolute;
        top: 0;
        left: 0;
        height: 100%;
        width: 100%;
        padding: 0;
        margin: 0;
        line-height: 1;
        opacity: 0;
        visibility: hidden; }
      .gjs-rte-select {
        height: 24px;
        background-color: #fff;
        border: 1px solid $grey-3;
        margin: 0; }
      &.gjs-rte-active {
        .gjs-rte-button {
          background-color: $grey-3;
          &:hover {
            background-color: #fff; } } } } } }

[data-tooltip] {
  position: relative; }

[data-tooltip]::after {
  font-family: Helvetica, sans-serif;
  background: rgba(55, 61, 73, 0.9);
  border-radius: $base-border-radius;
  bottom: 100%;
  color: #fff;
  content: attr(data-tooltip);
  display: block;
  font-size: 12px;
  left: 50%;
  line-height: normal;
  max-width: 32rem;
  opacity: 0;
  overflow: hidden;
  padding: 0.6rem 1rem;
  pointer-events: none;
  position: absolute;
  text-overflow: ellipsis;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0);
  transition: all 0.216s ease;
  z-index: 99; }

[data-tooltip]:focus::after,
[data-tooltip]:hover::after {
  opacity: 1;
  -webkit-transform: translate(-50%, -0.5rem);
  -ms-transform: translate(-50%, -0.5rem);
  transform: translate(-50%, -0.5rem); }

[data-tooltip][disabled],
[data-tooltip].disabled {
  pointer-events: auto; }

[data-tooltip-pos=right]::after {
  bottom: 50%;
  left: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%); }

[data-tooltip-pos=right]:focus::after,
[data-tooltip-pos=right]:hover::after {
  -webkit-transform: translate(0.5rem, 50%);
  -ms-transform: translate(0.5rem, 50%);
  transform: translate(0.5rem, 50%); }

[data-tooltip-pos=bottom]::after {
  bottom: auto;
  top: 100%;
  -webkit-transform: translate(-50%, 0);
  -ms-transform: translate(-50%, 0);
  transform: translate(-50%, 0); }

[data-tooltip-pos=bottom]:focus::after,
[data-tooltip-pos=bottom]:hover::after {
  -webkit-transform: translate(-50%, 0.5rem);
  -ms-transform: translate(-50%, 0.5rem);
  transform: translate(-50%, 0.5rem); }


[data-tooltip-pos=left]::after {
  bottom: 50%;
  left: auto;
  right: 100%;
  -webkit-transform: translate(0, 50%);
  -ms-transform: translate(0, 50%);
  transform: translate(0, 50%); }

[data-tooltip-pos=left]:focus::after,
[data-tooltip-pos=left]:hover::after {
  -webkit-transform: translate(-0.5rem, 50%);
  -ms-transform: translate(-0.5rem, 50%);
  transform: translate(-0.5rem, 50%); }
#containerStyle {
  .div-style {
    &.hide {
      display: none; } }
  .header-style {
    padding-top: 16px;
    font-size: 14px;
    &.hide {
      display: none; } } }

.collapse {
  width: 100%;
  text-align: right;
  .button-collapse {
    padding: 2px 0 10px;
    display: inline-block;
    margin-left: auto;
    margin-right: 0;
    cursor: pointer;
    font-family: $base-font-family;
    font-size: 11px;
    font-weight: 500; }
  .icon {
    line-height: 0.5em;
    vertical-align: middle;
    margin: 0 16px 0 8px; }
  &.hide {
    display: none; } }

@media (max-width: 768px) {
  .gjs-cv-canvas__frames {
    &.preview {
      &.tablet {
        transform: scale(0.3); } } } }
