@import 'src/styles/core.sass';

.templates-header {
  align-items: center;
  display: flex;
  height: $header-height;
  background-color: $header-bg-color;
  color: #fff;
  z-index: 1;
  .icon {
    font-size: 20px; }
  .dnd-breadcrumb {
    font-size: 12px; }
  &__cancel-btn.dnd-button-base[type="button"],
  &__switch-users-btn.dnd-button-base[type="button"] {
    font-size: 11px;
    margin-left: 16px;
    border: 1px solid #fff;
    &:hover {
      opacity: 0.8; } }

  &__logo {
    font-weight: 700;
    width: $sidebar-width;
    margin-right: 20px;
    background: #000;
    background: $top-bar-gradient; }

  &__content {
    width: calc(100% - 283px);
    padding-right: 16px;
    display: flex;
    align-items: center;
    justify-content: space-between; }

  &__buttons {
    display: flex;
    align-items: center;
    margin-right: 16px; }

  .dnd-button-base {
    font-size: 14px;
    padding: 4px 10px;
    color: #fff;
    &:not(:last-child) {
      margin-right: 16px; }
    .icon {
      margin-left: 20px;
      font-size: 14px; } } }
