@import 'src/styles/core.sass';

.dnd-breadcrumb {
  display: flex;
  align-items: center;
  font-size: 13px;
  color: #fff;
  font-weight: 400;
  a {
    color: #fff;
    @include event {
      color: inherit; } }
  &__template-status {
    text-transform: capitalize; }
  &__separator {
    margin: 0 6px;
    .icon {
      font-size: 14px !important; } }
  strong {
    text-transform: capitalize; } }
