@import '../../../styles/core.sass';

.new-template-modal {
  &__create-system-template {
    margin-top: 20px;
    .dnd-checkbox__label:not(.disabled) {
      color: $gold;
      .dnd-checkbox__icon {
        border-color: $gold;
        &.checked {
          background-color: $gold; } } } }
  &__templates {
    display: flex; }
  .new {
    font-weight: 500;
    font-size: $font-size-l;
    text-transform: capitalize;
    background-color: #fff;
    margin: 0;
    padding: 1em 40px; }
  .template-modal {
    background-color: #fff;
    margin-bottom: 23px;
    &__title {
      color: $purple-2;
      font-size: 12px;
      font-weight: 700;
      margin-bottom: 16px;
      button {
        border-radius: 4px;
        background-color: $grey-3;
        font-size: 14px;
        color: $black;
        margin-right: 8px;
        &.active-tab {
          color: #fff;
          background: $purple-2; } } }
    &__container {
      background-color: $grey-3;
      box-sizing: border-box;
      display: flex;
      padding: 18px;
      min-width: 100%;
      width: max-content;
      &-scroll {
        overflow-y: auto; } }
    &__loading-spinner {
      font-size: 36px; } }
  .template-modal__info {
    &__options {
      margin-bottom: 20px;
      &__main {
        display: flex;
        justify-content: space-between;
        @include breakpoint(mobile) {
          flex-direction: column; }
        .template-tags {
          width: 800px;
          @include breakpoint(mobile) {
            width: 100%; } }
        .template-name {
          width: 600px;
          @include breakpoint(mobile) {
            width: 100%; } }
        .dnd-form-control {
          @include breakpoint(mobile) {
            margin-bottom: 15px; }
          &:not(:last-child) {
            margin-right: 20px; } } } }

    &-btns {
      display: flex;
      justify-content: flex-end;
      width: 100%; } } }
