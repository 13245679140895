@import 'src/styles/core.sass';

.published-modal {
  display: flex;
  flex-direction: column;
  height: 100%;
  color: $black;

  &__content {
    flex: 1 1;
    margin-bottom: 40px; }

  &__buttons {
    display: flex;
    justify-content: flex-end; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } } }
