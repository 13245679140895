@import '../../../styles/core.sass';

.sidebar {
  display: flex;
  justify-content: space-between;
  flex-direction: column;
  position: relative;
  padding: 16px;
  background: $grey-4;
  width: $sidebar-width;
  font-size: 12px;
  z-index: 2;
  font-weight: 700;

  &__create-new-category {
    .icon {
      font-size: 10px; } }
  &__group {
    margin-bottom: 40px;
    &__header {
      display: flex;
      align-items: center;
      justify-content: space-between;
      margin-bottom: 16px;
      text-transform: capitalize;
      color: $header-bg-color;
      font-size: 14px;
      i {
        font-size: 16px;
        margin-right: 12px; } }
    &__links {
      font-weight: 500;
      &:first-of-type {
        border-top: 1px solid $grey-3;
        margin-left: -16px;
        margin-right: -16px;
        padding-left: 16px;
        padding-right: 16px; } } }


  &__link-item {
    position: relative;
    display: flex;
    align-items: center;
    color: $editor-right-panel-label-color;
    border-bottom: 1px solid $grey-3;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    font-size: 13px;
    &__favorited-templates,
    &__shared-templates {
      .icon {
        font-size: 18px; } }
    &__admin-action {
      color: $gold !important;
      .sidebar__link-item__number {
        color: $gold; } }
    &.active {
      background-color: $grey-3;
      color: $blue-1; }
    @include event {
      background-color: $grey-3; }
    &.hidden {
      color: $grey-1;
      .sidebar__link-item__number {
        color: $grey-1 !important; } }
    &__number {
      text-align: right;
      color: $editor-font-color;
      width: 24px;
      font-weight: 700;
      margin-left: 8px; }
    .dnd-icon-button {
      &:not(:last-of-type) {
        margin-right: 8px; } } }

  &__link {
    display: flex;
    align-items: center;
    padding: 12px 0;
    color: inherit;
    flex: 1;
    padding-right: 10px;
    word-break: break-word;
    @include event {
      color: inherit; }
    i {
      margin-right: 10px; } }

  &__version {
    font-size: 12px;
    font-weight: normal; } }
