@import 'src/styles/core.sass';

.code-import-modal {
  &__row {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 32px; }

  &__editor-wrapper {
    width: 48%; }

  &__editor {
    font-size: 13px;
    @include code-mirror-search; }

  &__buttons {
    display: flex;
    justify-content: space-between;
    margin-bottom: 30px; }

  &__button {
    &:not(:last-child) {
      margin-right: 8px; } }

  &__upload-button {
    position: relative;
    display: flex;
    &__input[type="file"] {
      width: 130px;
      color: transparent;
      overflow: hidden;
      margin: 0;
      padding: 0;
      &::-webkit-file-upload-button {
        visibility: hidden; }
      &:before {
        content: 'Select file';
        display: inline-block;
        background: #fff;
        color: $black;
        border: 1px dashed $grey-6;
        font-size: 12px;
        padding: 10px 30px;
        transition: all 0.5s;
        cursor: pointer; }
      &:hover:before {
        border-color: $grey-8; } } } }

