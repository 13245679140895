@import '../../../../styles/core.sass';

@keyframes flow {
  0% {
    left: -20px;
    opacity: 0; }
  50% {
    left: 20px;
    opacity: 0.3; }
  100% {
    left: 60px;
    opacity: 0; } }

.template-item {
  position: relative;
  width: 100%;
  input[type=checkbox] {
    display: none;
    &.visible {
      display: inline-block; } }
  &:has(input[type=checkbox]:checked) {
    border: 1px solid $purple-2;
    border-radius: $base-border-radius;
    &.system-template {
      border-color: $gold;
      input[type=checkbox] {
        &:checked {
          border-color: $gold;
          background-color: $gold; }
        &:focus,
        &:active {
          outline-color: $gold; } } } }

  button[data-reach-menu-button] {
    background: none;
    color: $grey-7;
    font-size: 12px;
    margin: 0 0 0 8px;
    padding: 0; }

  &.featured-template {
    border: 1px solid $blue-1;
    border-radius: $base-border-radius;
    .ribbon {
      position: absolute;
      top: -12px;
      left: 0;
      &__front {
        font-size: 12px;
        text-transform: uppercase;
        display: inline-block;
        background-color: $blue-1;
        width: auto;
        position: relative;
        left: -10px;
        z-index: 1;
        padding: 2px 8px;
        color: #fff; }
      &__edge {
        position: absolute;
        z-index: 1;
        border-style: solid;
        border-width: 0 10px 5px 0;
        height: 0;
        width: 0;
        bottom: -5px;
        left: -10px;
        border-color: transparent $black-2 transparent transparent; }
      &__glow {
        background: #fff;
        width: 10px;
        height: 100%;
        z-index: 3;
        position: absolute;
        animation: flow 1.5s linear infinite;
        transform: skew(20deg);
        background: linear-gradient(to right, rgba(255, 255, 255, 0) 0%, rgba(255, 255, 255, 0) 1%, rgba(255, 255, 255, 1) 100%); } } }

  &__wrapper {
    background: #fff;
    border-radius: $base-border-radius;
    padding: 16px;
    height: 100%;
    display: flex;
    flex-direction: column; }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin: 0 0 16px;
    &__share-status,
    &__lock-status {
      font-size: 14px !important;
      color: $grey-7;
      margin: 0 0 0 4px;
      background: transparent;
      padding: 0;
      &:hover,
      &:active,
      &:focus {
        background: transparent; }
      .icon {
        font-size: 14px; } }
    &__lock-status {
      &.icon-lock-open {
        color: $green; } }
    &__checkbox {
      &[type=checkbox] {
        background: none;
        border: 1px solid $purple-2;
        border-radius: 2px;
        appearance: none;
        width: 16px;
        height: 16px;
        cursor: pointer;
        position: relative;
        margin: 0 10px 0 0;
        &:focus,
        &:active {
            outline: 1px solid $grey-6;
            outline-offset: 1px; }
        &:checked {
            background: $purple-2 url("data:image/svg+xml;base64,PHN2ZyB4bWxucz0iaHR0cDovL3d3dy53My5vcmcvMjAwMC9zdmciIGhlaWdodD0iMjQiIHZpZXdCb3g9IjAgLTk2MCA5NjAgOTYwIiB3aWR0aD0iMjQiPjxwYXRoIGZpbGw9IiNmZmZmZmYiIGQ9Im0zODItMzU0IDMzOS0zMzlxMTItMTIgMjguNS0xMnQyOC41IDEycTEyIDEyIDEyIDI4LjVUNzc4LTYzNkw0MTAtMjY4cS0xMiAxMi0yOCAxMnQtMjgtMTJMMTgyLTQ0MHEtMTItMTItMTEuNS0yOC41VDE4My00OTdxMTItMTIgMjguNS0xMnQyOC41IDEybDE0MiAxNDNaIi8+PC9zdmc+") center/16px no-repeat; } } }
    .dnd-icon-button {
      .icon {
        color: $grey-7; } }
    &__favorite {
      background: transparent;
      color: $purple-2;
      margin: 0;
      padding: 0;
      font-size: 12px;
      &:focus,
      &:hover {
        background: transparent;
        position: relative;
        &:before {
          content: '';
          width: 20px;
          height: 20px;
          background: #000;
          border-radius: 50%;
          opacity: 0.05;
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%); } } }
    &__title {
      font-size: 16px;
      font-weight: 700;
      padding: 0;
      margin: 0 8px 0 0;
      line-height: 27px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      flex: 1;
      cursor: pointer;
      color: $black-1;
      &__status {
        font-family: monospace;
        font-size: 8px;
        text-transform: capitalize;
        color: #fff;
        font-weight: 100;
        border-radius: 8px;
        padding: 2px 6px;
        margin: 0 8px;
        display: inline-block;
        &.published {
          background: $green; }
        &.draft {
          background: $blue-1; } } }
    &__menu[data-reach-menu-list] {
      border-radius: $base-border-radius;
      min-width: 160px;
      padding: 0;
      font-size: 12px;
      color: $black;
      .template-item__header__menu--delete {
        color: $red; }
      div[data-reach-menu-item] {
        display: flex;
        align-items: center;
        padding: 6px 12px;
        border-bottom: 1px solid $grey-3;
        @include event {
          background: $grey-3; }
        .icon {
          margin-right: 12px; }
        &:last-child {
          border-bottom: none; } } } }

  &__info {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-top: 12px;
    font-size: 11px;
    color: $grey-7;
    text-transform: capitalize; }

  &__content {
    align-items: center;
    background-color: #fff;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: 100%;
    box-sizing: border-box;
    cursor: pointer;
    display: flex;
    height: 100%;
    justify-content: center; } }
