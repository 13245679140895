@import 'src/styles/core.sass';
#editor {
  display: flex;
  flex-direction: column;
  overflow: hidden;
  .dnd-loading {
    z-index: 100; }
  .font {
    font-family: $base-font-family;
    font-size: 14px; }
  // Modals
  #canvas {
    .gjs-mdl-dialog.gjs-one-bg.gjs-two-color {
      background-color: #fff;
      color: $black;
      max-width: 100%;
      .tui-image-editor-range-wrap {
        display: flex;
        justify-content: center;
        align-items: baseline;
        .tui-image-editor-newline {
          margin-right: 10px; }
        .tui-image-editor-range-value {
          color: #000;
          border: 0;
          max-width: none; } }
      .tui-image-editor-container {
        .tui-image-editor-submenu > div[class^="tui-image-editor-menu"] {
          padding-top: 30px; }
        .tui-image-editor-menu-resize {
          .tui-image-editor-submenu-align {
            width: 100%;
            text-align: center;
            margin: 10px 0 15px; } }
        .tui-image-editor-range-value {
          margin-right: 5px; }
        input.tui-colorpicker-palette-hex {
          background: $grey-3;
          border: 0;
          max-width: 114px;
          margin: 0; } }
      .tui-image-editor {
        &__apply-btn {
          background-color: $blue-1 !important;
          display: flex;
          align-items: center;
          .icon {
            display: block;
            margin-right: 5px;
            font-size: 16px; } } } }
    .CodeMirror {
      height: 480px; } }
  .edit-url-modal {
    &__form-title {
      color: $grey-7;
      padding: 0;
      margin: 0 0 40px; }
    &__select-wrapper {
      position: relative;
      &:before {
        content: '\e904';
        font: $font-icons;
        position: absolute;
        right: 14px;
        top: 12px;
        pointer-events: none; } }
    &__buttons {
      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
      .dnd-button-base {
        &.contained {
          background: $black-2; }
        &.text {
          color: $black-2; } } }
    .gjs-mdl-dialog {
      padding: 0;
      .gjs-mdl-title {
        font-size: 16px;
        font-weight: 500;
        display: flex;
        align-items: center;
        margin: 0;
        &:before {
          content: "\e955";
          font: $font-icons;
          margin-right: 8px;
          font-size: 16px; } }
      label {
        font-size: 12px; }
      select {
        -moz-appearance: none;
        -webkit-appearance: none;
        -webkit-border-radius: 0;
        appearance: none;
        outline-width: 0;
        border-radius: $base-border-radius;
        border: 1px solid $grey-5 !important;
        padding: 12px 10px;
        margin: 0 0 30px 0;
        width: 100%;
        font-size: 12px; }
      input[type="url"],
      input[type="text"] {
        width: 100%;
        max-width: none;
        background-color: transparent;
        font: 400 12px/12px "Source Sans Pro", sans-serif;
        color: $black;
        margin: 0 0 30px 0;
        padding: 11px;
        transition: none;
        min-width: 30%;
        &::placeholder {
          color: $grey-1; }
        &:hover,
        &:active,
        &:focus {
          background-color: transparent; } }
      button {
        padding: 10px 25px; } } }
  .gjs-mdl-dialog {
    background-color: #fff;
    text-shadow: none;
    .gjs-mdl-title {
      color: $black-2;
      font-size: 16px;
      font-weight: 500;
      display: flex;
      align-items: center;
      .icon {
        margin-right: 21px; } }
    .gjs-mdl-header {
      display: flex;
      align-items: center;
      margin-bottom: 20px;
      padding: 26px;
      border-bottom: 1px solid $grey-4; }
    .gjs-mdl-content {
      font-weight: normal;
      border-color: $transparent;
      padding: 0 26px 26px;
      .gjs-div-import {
        display: flex;
        justify-content: flex-end;
        align-items: center;
        margin-bottom: 16px;
        padding: 0;
        .gjs-div-import-file {
          @include themeButton;
          margin-left: 10px;
          padding: 10px 20px;
          .icon {
            margin-right: 10px;
            position: relative;
            top: -1px; } }
        input[type="file"] {
          display: none; } }
      .gjs-btn-prim {
        @include themeButton;
        &.gjs-btn-import__custom-code {
          margin-top: 16px; } }

      .gjs-modal__code-wrapper {
        position: relative; }
      .CodeMirror + button {
        background-color: $blue-1;
        color: #fff;
        padding: 8px 30px;
        border-radius: 30px; } }
    .gjs-mdl-btn-close {
      position: absolute;
      top: 15px;
      right: 22px;
      opacity: 1;
      background-color: transparent;
      border-radius: 0;
      border: none;
      box-shadow: none;
      color: $grey-7;
      cursor: pointer;
      margin: 0;
      padding: 0;
      transition: none;
      font-size: 40px;
      line-height: 30px; }
    #gjs-cm-htmlmixed, #gjs-cm-css {
      #gjs-cm-title {
        color: $black; } }
    input {
      background-color: #fff;
      border: 1px solid $grey-6;
      max-width: $phablet; }
    button {
      padding: 5px 40px;
      margin-top: 20px; }
    .gjs-asset-manager {
      display: flex;
      flex-direction: column;
      input {
        max-width: 100%;
        margin: 0; }
      > div {
        float: none;
        width: 100%; }
      .gjs-am-file-uploader {
        margin-top: 30px;
        form {
          border-color: $blue-1;
          background-color: tint($blue-1, 90%);
          padding-right: 50px; }
        #gjs-am-uploadFile, #gjs-am-title {
          padding: 60px;
          color: $grey-2;
          cursor: pointer; } }

      .gjs-am-assets-cont {
        order: -1;
        background-color: $transparent;
        padding: 0;
        height: auto; }
      .gjs-am-assets-header {
        padding: 15px 0; }
      .gjs-am-assets {
        display: flex;
        flex-wrap: nowrap;
        height: auto;
        .gjs-am-asset {
          flex: 0 0 16%;
          min-width: 0;
          background-color: #fff;
          border: none;
          margin-right: 10px;
          > div {
            float: none;
            width: 100%; }
          .gjs-am-preview-cont {
            height: 140px; }
          .gjs-am-close {
            color: #fff;
            text-shadow: 0 0 2px black;
            text-align: right;
            z-index: 1;
            font-size: $font-size-xxl;
            line-height: 1em;
            padding: 0 4px; }
          .gjs-am-name {
            @extend %ellipsis; } } }
      button.gjs-btn-prim {
        border-radius: 100px;
        color: #fff;
        @include bg-color-hover($red);
        padding: 5px 20px;
        margin: 10px; } } } }
