@include font-face("icons", "/fonts/icons/icons", normal, normal);

[class^="icon-"], [class*=" icon-"] {
  font-family: "icons";
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: inherit;

  // Better Font Rendering =========== //
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;

  display: inline-block;
  text-decoration: inherit;

  text-align: center;
  font-size: 120%;

  // Avoid text selection
  user-select: none; }

.icon-spinner {
  animation: loadingCircle 1s infinite linear; }

.icon {
  font-family: "icons"; }

.icon-preview:before {
  content: "\e95b"; }

.icon-ios-share:before {
  content: "\e95a"; }

.icon-move-selection-up:before {
  content: "\e94e"; }

.icon-move-selection-down:before {
  content: "\e959"; }

.icon-history:before {
  content: "\e94c"; }

.icon-lock-open-right:before {
  content: "\e94a"; }

.icon-background-grid-small:before {
  content: "\e949"; }

.icon-grid-on:before {
  content: "\e93f"; }

.icon-table-rows:before {
  content: "\e941"; }

.icon-draft:before {
  content: "\e93e"; }

.icon-folder-special:before {
  content: "\e93a"; }

.icon-star:before {
  content: "\e93c"; }

.icon-star-fill:before {
  content: "\e93d"; }

.icon-share:before {
  content: "\e939"; }

.icon-lock-open:before {
  content: "\e938"; }

.icon-folder-shared:before {
  content: "\e935"; }

.icon-magic-wand:before {
  content: "\e933"; }

.icon-line-spacing-1x:before {
  content: "\e92b"; }

.icon-line-spacing-2x:before {
  content: "\e92c"; }

.icon-line-spacing-3x:before {
  content: "\e930"; }

.icon-line-spacing-4x:before {
  content: "\e932"; }

.icon-align-items-flex-start:before {
  content: "\e928"; }

.icon-align-items-flex-end:before {
  content: "\e929"; }

.icon-align-items-center:before {
  content: "\e92a"; }

.icon-youtube-searched-for:before {
  content: "\e91f"; }

.icon-zoom-in:before {
  content: "\e920"; }

.icon-zoom-out:before {
  content: "\e927"; }

.icon-info:before {
  content: "\e91e"; }

.icon-lock:before {
  content: "\e90c"; }

.icon-code:before {
  content: "\e908"; }

.icon-cloud-download:before {
  content: "\e906"; }

.icon-sell:before {
  content: "\e915"; }

.icon-business-center:before {
  content: "\e910"; }

.icon-view-compact:before {
  content: "\e907"; }

.icon-electric-bolt:before {
  content: "\e90e"; }

.icon-extension:before {
  content: "\e912"; }

.icon-add-comment:before {
  content: "\e903"; }

.icon-phone-iphone:before {
  content: "\e945"; }

.icon-align-right:before {
  content: "\e901"; }

.icon-align-center:before {
  content: "\e92f"; }

.icon-align-left:before {
  content: "\e931"; }

.icon-align-justify:before {
  content: "\e905"; }

.icon-content-copy:before {
  content: "\e93b"; }

.icon-dark-mode:before {
  content: "\e909"; }

.icon-palette:before {
  content: "\e90a"; }

.icon-delete:before {
  content: "\e942"; }

.icon-undo:before {
  content: "\e937"; }

.icon-handyman:before {
  content: "\e92e"; }

.icon-desktop-windows:before {
  content: "\e944"; }

.icon-image:before {
  content: "\e914"; }

.icon-layers:before {
  content: "\e926"; }

.icon-light-mode:before {
  content: "\e916"; }

.icon-edit:before {
  content: "\e918"; }

.icon-add:before {
  content: "\e919"; }

.icon-visibility:before {
  content: "\e91a"; }

.icon-redo:before {
  content: "\e936"; }

.icon-straighten:before {
  content: "\e91d"; }

.icon-settings:before {
  content: "\e92d"; }

.icon-tablet-mac:before {
  content: "\e946"; }

.icon-text-format:before {
  content: "\e925"; }

.icon-waymore:before {
  content: "\e90f"; }

.icon-border-clear:before {
  content: "\e934"; }

.icon-bookmark:before {
  content: "\e940"; }

.icon-mail:before {
  content: "\e94d"; }

.icon-drag-mode:before {
  content: "\e90c"; }

.icon-check-circle:before {
  content: "\e90d"; }

.icon-publish:before {
  content: "\e91b"; }

.icon-restore-from-trash:before {
  content: "\e91c"; }

.icon-save:before {
  content: "\e921"; }

.icon-unpublish:before {
  content: "\e943"; }

.icon-spinner:before {
  content: "\e900"; }

.icon-file-upload:before {
  content: "\e913"; }

.icon-link:before {
  content: "\e955"; }

.icon-cloud-upload:before {
  content: "\e948"; }

.icon-search:before {
  content: "\e94b"; }

.icon-expand-more:before {
  content: "\e904"; }

.icon-chevron-left:before {
  content: "\e922"; }

.icon-chevron-right:before {
  content: "\e923"; }

.icon-expand-less:before {
  content: "\e924"; }

.icon-close:before {
  content: "\e917"; }

.icon-vertical-split:before {
  content: "\e902"; }

.icon-horizontal-split:before {
  content: "\e90b"; }

.icon-help:before {
  content: "\e911"; }

.icon-hourglass:before {
  content: "\e933"; }

.icon-photo-library:before {
  content: "\e94f"; }

.icon-more-vert:before {
  content: "\e950"; }

.icon-more-horiz:before {
  content: "\e952"; }

.icon-visibility-off:before {
  content: "\e951"; }

.icon-bold:before {
  content: "\e953"; }

.icon-italic:before {
  content: "\e954"; }

.icon-strikethrough:before {
  content: "\e956"; }

.icon-text-fields:before {
  content: "\e957"; }

.icon-format-underlined:before {
  content: "\e958"; }

.icon-send:before {
  content: "\e947"; }
