// ---------TYPOGRAPHY---------
$base-font-family: "Poppins", Helvetica, Arial, Sans, Liberation Sans, sans-serif;
$heading-font-family: $base-font-family;
$font-icons: normal normal normal 12px/1 icons;

// ---------OTHER SIZES---------
$base-border-radius: 4px;
$header-height: 40px;
$sidebar-width: 283px;
$logo-size: $header-height;
$editor-sidebar-width: 400px;
$editor-traits-sidebar-width: 350px;
$grid-gap: 25px;

// ---------COLORS---------
$dark-bg: #1E1F29;
$red: #FB6868;
$green: #009A6C;
$orange: #FF5C00;
$gold: #c79464;
$gold-1: #f5f1e0;

$black: #43425D;
$black-1: #1c1d1f;
$black-2: #4D4F5C;
$blue-4: #172b4d;
$blue-5: #084298;
$blue-6: #cfe2ff;

$purple-1: #ABABC4;
$purple-2: #7C7599;

$blue-1: #65a0ba;
$blue-2: #CBE9FF;
$blue-3: #F1F9FF;

$grey-1: #a8a8a8;
$grey-2: #BEC5C6;
$grey-3: #F4F4F4;
$grey-4: #E8E8E9;
$grey-5: #d1d1d2;
$grey-6: #d5d5d5;
$grey-7: #77787A;
$grey-8: #4A4B4D;
$grey-9: #A1A1A1;

$bg-color: #E6E6E6;
$header-bg-color: $black-1;
$default-btn-color: $blue-1;
$primary-btn-color: $blue-1;
$secondary-btn-color: $gold;
$delete-btn-color: $red;

// ---------Editor---------
$editor-accent-color: #3B98E3;
$editor-font-color: #8780A3;
$editor-border-color: $editor-font-color;
$editor-right-panel-label-color: #1E1F1F;
$base-font-family-editor: "Helvetica Neue", Helvetica, Arial, sans-serif;

// ---------SHADOW & GRADIENT---------
$block-shadow: 0 3px 6px #00000029;
$top-bar-gradient: linear-gradient(276deg, rgba(28,29,31,1) 16%, rgba(0,53,98,1) 33%);
