@mixin button($color) {
  // Ex: +button(red)
  box-sizing: border-box;
  border-radius: 50px;
  padding: 11px 20px 9px;
  display: inline-block;
  background-color: $color;
  cursor: pointer;
  transition: all 0.25s ease-in-out;
  @if $color == #fff {
    color: $black; }
  @else {
    color: #fff; }
  &:hover, &:active, &:focus {
    background-color: darken($color, 10%); }
  &[disabled] {
    background-color: $grey-2;
    opacity: 0.4;
    cursor: not-allowed; } }

@mixin pseudo-expand-more {
  content: '\e904';
  font: $font-icons; }

@mixin pseudo-expand-less {
  content: '\e924';
  font: $font-icons; }

@mixin clearfix {
  &:after {
    content: '';
    display: table;
    width: 100%;
    clear: both; } }

@mixin themeButton {
  align-items: center;
  background-color: $black;
  border-radius: $base-border-radius;
  border: none;
  color: #fff;
  cursor: pointer;
  display: flex;
  font: 400 12px/14px $base-font-family;
  justify-content: center;
  margin: 0;
  padding: 10px 25px;

  &:disabled {
    box-shadow: none;
    cursor: default;
    opacity: 0.7; } }

@mixin block-lock {
  &.gjs-block--lock {
    opacity: 0.6;
    position: relative;
    cursor: not-allowed;
    .gjs-block__media {
      pointer-events: auto;
      position: absolute;
      top: 5px;
      right: 5px; } } }

@mixin code-mirror-search {
  .CodeMirror-dialog-top {
    display: flex;
    align-items: center;
    padding: 8px;
    border: none;
    color: $grey-5;
    background-color: #000;
    box-shadow: rgba(0, 0, 0, 0.3) 0 5px 5px;
    .CodeMirror-search-hint {
      display: none; }
    .CodeMirror-search-field {
      margin: 0 0 0 8px;
      padding: 5px 8px;
      border-radius: $base-border-radius;
      background: none;
      border: 1px solid $grey-5;
      font-size: 11px; } } }


