@import 'src/styles/core.sass';

.dnd-logo {
  display: flex;
  align-items: center;
  height: $logo-size;
  padding: 0 18px;
  color: #fff;
  font-size: 14px;

  .icon {
    margin-right: 10px; } }
