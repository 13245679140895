@import 'src/styles/core.sass';

.dnd-tag {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background-color: $blue-2;
  color: $black;
  border-radius: $base-border-radius;
  font-size: 12px;
  line-height: 1;
  padding: 6px;
  white-space: nowrap;

  &__delete-button {
    background-color: transparent;
    border-radius: 0;
    border: none;
    box-shadow: none;
    color: currentColor;
    cursor: pointer;
    line-height: 1;
    margin: 0 0 0 6px;
    padding: 0;
    transition: none;
    width: 10px;
    @include event {
      background-color: initial; } } }
