@import 'src/styles/core.sass';

.dnd-radio-button {
  &__label {
    display: inline-block;
    padding: 10px;
    background-color: $blue-3;
    color: $blue-1;
    border-radius: $base-border-radius;
    font: 400 12px/14px $base-font-family;
    cursor: pointer;
    margin: 0;
    &.active {
      background-color: $blue-2; }
    &:not(:last-child) {
      margin-right: 10px; } }

  &__input {
    display: none !important; } }
