@import 'src/styles/core.sass';
$toggle-size: 56px;
.home {
  height: 100vh;
  display: flex;
  flex-direction: column;
  #interface-switcher {
    padding: 10px 48px;
    text-align: center;
    & > span {
      margin-bottom: 10px;
      display: block; }
    .switcher {
      border-radius: 50px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 10px 15px;
      transition: border-color 0.15s ease-in-out;
      .toggle {
        border-radius: 50px;
        flex: 1 1 auto;
        max-width: $toggle-size;
        cursor: pointer;
        position: relative;
        box-sizing: border-box;
        height: ($toggle-size / 2.25);
        transition: all 0.15s ease-in-out;
        &:before {
          content: '';
          @include size($toggle-size / 3);
          top: 2px;
          display: block;
          border-radius: 100%;
          transition: all 0.25s ease-in-out;
          position: absolute; } }
      .icon {
        font-size: $font-size-l;
        flex: 0 0 auto;
        display: block;
        margin: 0 5px;
        line-height: 1em;
        cursor: pointer; } } }

  &__content {
    display: flex;
    height: calc(100vh - #{$header-height});
    &__inner {
      background: $grey-3;
      width: 100%;
      position: relative; } }

  .sidebar {
    flex: 0 0 auto;
    overflow: auto;
    height: 100%; }

  .templates-wrapper {
    flex: 1 1 auto;
    overflow: auto;
    height: 100%;
    background: $grey-3;
    padding-top: 134px;
    &.favorited-templates,
    &.shared-templates {
      padding-top: 86px; } } }
