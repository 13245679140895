@import '../../../../styles/core.sass';

.new-template-modal {
  .title-tooltip {
    max-width: 400px;
    font-size: 11px;
    line-height: 18px;
    padding: 2px 8px;
    font-weight: normal;
    background: $blue-4 !important;
    &:after {
      background: $blue-4 !important; }
    span {
      white-space: normal;
      position: relative;
      z-index: 3; } }
  .new-template {
    width: 100%;
    margin-right: 32px;
    cursor: pointer;
    img {
      width: 240px;
      position: absolute;
      top: 0;
      transition: top 2s; }
    &__title {
      font-size: 14px;
      font-weight: bold;
      color: $grey-8;
      margin: 16px auto 8px;
      padding: 0;
      text-align: center;
      max-width: 200px;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap; }
    &:last-child {
      margin-right: 0; }
    &.active {
      .new-template__frame {
        outline: 2px solid $blue-1; } }
    &__frame {
      width: 240px;
      height: 270px;
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: center;
      overflow: hidden;
      background-color: #fff;
      outline: 1px solid $grey-4;
      &:hover > img {
        top: -30px;
        transition: all 2s; } } } }
