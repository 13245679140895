@import 'src/styles/core.sass';

.general-settings-modal {
  color: $black-1;
  display: flex;
  flex-direction: column;
  &__header {
    margin-bottom: 20px; }
  &__content-wrapper {
    flex: 1 1 400px;
    button {
      padding: 0;
      margin: 0;
      cursor: pointer;
      border: none;
      box-shadow: none;
      background-color: transparent;
      border-radius: 0;
      transition: none; } }

  &__content {
    margin-top: 40px;
    font-size: 12px;
    &__headings-info {
      padding: 10px 12px;
      margin: 0;
      font-size: 11px;
      color: $blue-5;
      background: $blue-2;
      border-radius: $base-border-radius; }
    &.headings-setting {
      margin-top: 20px;
      .general-settings-modal__options-wrapper {
        padding-left: 20px;
        display: flex;
        align-items: center;
        justify-content: space-between;
        &__option-item {
          margin-right: 50px;
          &__label {
            margin-bottom: 10px; } } }
      .general-settings-modal__form-item-wrapper {
        flex: none; }
      .general-settings-modal__content-item {
        margin: 0;
        padding: 16px;
        &:nth-child(even) {
          background: lighten($black-1, 87%); }
        align-items: center;
        &__title {
          flex: 1;
          font-size: 16px; } } } }

  &__content-item {
    display: flex;
    align-items: flex-start;
    &:not(:first-child) {
      margin-top: 40px; } }
  &__form-item-wrapper {
    position: relative;
    flex: 0 0 35%;
    margin-right: 60px;
    display: flex;
    align-items: center;
    flex-wrap: wrap; }

  &__form-item-info {
    position: absolute;
    left: 0;
    top: 100%;
    width: 100%;
    color: $black-2; }
  &__form-item-label {
    font-weight: 400;
    margin-right: 32px; }
  &__form-item {
    &.range-wrapper {
      display: flex;
      justify-content: space-between;
      align-items: center;
      width: 100%;
      color: $blue-1; }
    &.range {
      flex: 1 0;
      margin: 18px 20px;
      -webkit-appearance: none;
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: 10px;
        width: 10px;
        border-radius: 10px;
        background: $blue-2;
        border: 4px solid $blue-1;
        cursor: pointer;
        box-shadow: 0px 0px 0px 2px $blue-2; }
      &::-moz-range-thumb {
        box-shadow: 0px 0px 0px 2px $blue-2;
        height: 10px;
        width: 10px;
        border-radius: $base-border-radius;
        background: $blue-1;
        cursor: pointer; }
      &::-webkit-slider-runnable-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: $blue-1;
        border-radius: 10px; }
      &::-moz-range-track {
        width: 100%;
        height: 10px;
        cursor: pointer;
        background: $blue-1;
        border-radius: 10px; } }
    &.number-range {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 32px;
      color: $black-1;
      button {
        height: 32px;
        width: 32px;
        border: 1px solid $grey-5;
        border-radius: 32px;
        color: inherit;
        font-size: 24px;
        &:first-child {
          transform: translate(-16px, -1px); }
        &:last-child {
          transform: translate(16px, -1px); }

        @include event {
          background-color: rgba($grey-5, 0.5); } }
      & > div {
        line-height: 32px; } }
    &.color-picker {
      position: relative;
      .color-picker__input {
        border-radius: $base-border-radius;
        display: flex;
        justify-content: space-between;
        align-items: center;
        padding: 5px 10px;
        border: 1px solid $grey-5;
        width: 190px;
        input {
          background-color: transparent;
          font-size: 12px;
          color: $black-1;
          width: 100%;
          padding: 0;
          margin: 0; }
        button {
          width: 28px;
          height: 28px;
          border: 1px solid $grey-5;
          border-radius: $base-border-radius;

          @include event {
            border: 1px solid $grey-5; } } }
      .color-picker__popup {
        position: absolute;
        right: 0;
        bottom: 0; } }
    &.font-select {
      margin-top: 10px;
      width: 100%;
      padding: 10px;
      border: 1px solid $grey-5;
      border-radius: $base-border-radius;
      label {
        display: flex;
        justify-content: space-between;
        margin: 0;
        font-size: 10px;
        color: $black-1;
        line-height: 18px; }
      select {
        margin: 0;
        border: none;
        cursor: pointer;
        font-size: 12px;
        color: $black-1; } }
    &.paragraph-bottom-space {
      width: 100%;
      margin-top: 15px; }
    &.line-spacing {
      margin-top: 15px;
      width: 100%;
      display: flex;
      label {
        padding: 8px 20px;
        background-color: $blue-3;
        color: $blue-1;
        border-radius: $base-border-radius;
        display: flex;
        align-items: center;
        &.active {
          background-color: $blue-2; }
        &:not(:last-child) {
          margin-right: 10px; } }
      input {
        display: none; } } }
  &__description-header {
    margin: 0;
    margin-bottom: 16px;
    font-weight: 400;
    color: $black-2; }
  &__description {
    margin: 0;
    color: $grey-9; }

  &__buttons {
    margin-top: 20px;
    display: flex;
    justify-content: flex-end;
    button {
      &:not(:last-child) {
        margin-right: 8px; } } } }

