@import 'src/styles/core.sass';

.dnd-multiple-combobox {
  &__loading {
    padding: 0 8px;
    font-size: 12px;
    color: $blue-1; }

  &__button {
    padding: 9px; }

  &__menu {
    position: absolute;
    width: 100%;
    background-color: #fff;
    z-index: 1;
    box-shadow: $block-shadow;
    display: none;
    &.isOpen {
      display: block; } }

  &__list {
    max-height: 150px;
    overflow-y: auto;

    &-item {
      &.highlighted {
        background-color: $blue-3; }
      &.selected {
        background-color: $blue-2; } } }

  &__empty-list {
    padding: 5px 10px;
    color: $grey-1; }

  &__tag {
    margin: 2px 0 2px 5px;
    .icon {
      margin: 0; } }

  .dnd-input__before {
    flex-wrap: wrap; } }
