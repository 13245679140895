@import 'src/styles/core.sass';

.templates {
  padding: 23px 50px 50px;
  &.small-grid-view {
    .view-templates-preview {
      grid-template-columns: repeat(auto-fill, minmax(200px, 1fr)); }
    .template-item {
      &__header {
        &__title {
          font-size: 14px; }
        &__actions {
          .icon {
            font-size: 12px; } } }
      &__info {
        font-size: 10px; } } }
  &.list-view {
    .new-template-item {
      display: none; }
    .view-templates-preview {
      grid-template-columns: none;
      grid-template-rows: none;
      grid-auto-rows: auto; }
    .template-item {
      &__content {
        display: none; }
      &__header {
        &__title {
          max-width: 400px;
          display: inline-block; }
        &__actions {
          float: right; }
        margin-bottom: 0;
        display: block; }
      &__wrapper {
        padding: 12px 16px; } } }
  &__scroll-more {
    width: 100%;
    height: 80px;
    background: $grey-3;
    position: fixed;
    bottom: 0;
    right: 0;
    display: flex;
    align-items: flex-end;
    justify-content: center;
    transition: bottom ease .3s;
    &--deactive {
      bottom: -120px; } }
  .view-templates-preview {
    display: grid;
    flex-wrap: wrap;
    grid-gap: 20px;
    grid-template-columns: repeat(auto-fill, minmax(270px, 1fr)); }

  &__load-more {
    display: flex;
    justify-content: center;
    align-items: center; } }
