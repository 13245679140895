@import 'src/styles/core.sass';

.dnd-checkbox {
  &__input[type="checkbox"] {
    display: none; }

  &__label {
    display: flex;
    align-items: center;
    &:not(.disabled) {
      @include event {
        cursor: pointer; } } }

  &__icon-wrapper {
    position: relative;
    width: 16px;
    height: 16px;
    margin-right: 8px;
    color: $blue-1; }

  &__icon {
    position: relative;
    display: block;
    width: 100%;
    height: 100%;
    border: 1px solid $grey-1;
    border-radius: 2px;
    transition: all .3s ease;
    outline: none;
    &:not(.disabled) {
      @include event {
        border-color: currentColor; } }

    &.checked {
      background-color: currentColor;
      border-color: currentColor;
      &::after {
        content: "";
        top: 50%;
        left: 22%;
        width: 5.7px;
        height: 9.2px;
        position: absolute;
        display: block;
        border: 2px solid #fff;
        border-top: 0;
        border-left: 0;
        transform: rotate(45deg) scale(1) translate(-50%,-50%);
        opacity: 1; } } } }
