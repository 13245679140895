.dnd-not-found {
    background-color: #9ca4b5;
    width: 100%;
    height: 100vh;
    position: relative;
    &__wrapper {
      background-image: url(https://www.waymore.io/wp-content/uploads/2021/08/404_letters_compressed.png);
      background-position: center center;
      background-repeat: no-repeat;
      background-size: 1150px auto;
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      position: absolute;
      bottom: 0;
      left: 50%;
      transform: translateX(-50%);
      h1,
      p {
        margin: 0;
        padding: 0;
        font-size: 3rem;
        color: #fff; }
      h1 {
        margin-bottom: 1rem; } } }
